import React, { createRef, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAuth } from '../Auth/Provider'
import styles from './Project.module.scss'
import wsClient from '../WebSocket/WebSocketClient'

type Params = {
  id: string
}

type Project = {
  id: string
  name: string
}

function Project(): React.JSX.Element {
  const { isAuthenticated, getToken, login, logout } = useAuth()
  const params = useParams<Params>()
  const screen = createRef<HTMLVideoElement>()
  const source = createRef<HTMLVideoElement>()

  const [project, setProject] = useState<Project | null>(null)

  useEffect(() => {
    getToken().then((token) => {
      fetch('/api/v1/projects/' + params.id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then((response) => response.json())
        .then((data) => setProject(data))
    })
  }, [getToken])

  function makeDisplayPeerConnection() {
    const peerConnection = new RTCPeerConnection()

    peerConnection.onconnectionstatechange = () => {
      console.log('Display: RTC State', peerConnection.connectionState)
    }

    peerConnection.ontrack = (e) => {
      const display = screen.current

      if (display === null) {
        return
      }

      display.srcObject = e.streams[0]
      display.muted = true
      display.play().catch(() => {})

      console.log('Display: Connected')
    }

    return peerConnection
  }

  function makeSourcePeerConnection(offer: RTCSessionDescriptionInit) {
    return new Promise<RTCSessionDescription>((resolve) => {
      const peerConnection = new RTCPeerConnection()

      peerConnection.onconnectionstatechange = () => {
        console.log('Source: RTC State', peerConnection.connectionState)
      }

      peerConnection.onicegatheringstatechange = () => {
        if (peerConnection.iceGatheringState === 'complete') {
          const answer = peerConnection.localDescription
          if (answer !== null) {
            resolve(answer)
          }
        }
      }

      peerConnection.setRemoteDescription(offer).then(() => {
        navigator.mediaDevices
          .getUserMedia({
            video: {
              width: { ideal: 854 },
              height: { ideal: 480 },
              frameRate: { ideal: 25 },
            },
            audio: false,
          })
          .then((media) => {
            const me = source.current

            if (me !== null) {
              me.srcObject = media
              me.muted = true
              me.play().catch(() => {})
            }

            media.getTracks().forEach((track) => {
              peerConnection.addTrack(track, media)
            })

            peerConnection.createAnswer().then((answer) => {
              peerConnection.setLocalDescription(answer)
            })
          })
      })
    })
  }

  useEffect(() => {
    if (project === null) {
      return
    }

    const ws = wsClient.connect()

    ws.onOpen(() => {
      ws.send({
        type: 'bind-to-project',
        project: project.id,
        data: {},
      })
      ws.send({
        type: 'start',
        data: {
          project: project.id,
        },
      })
    })

    const display = screen.current

    if (display === null) {
      return
    }

    const displayPeerConnection = makeDisplayPeerConnection()

    ws.onMessage(async (e: MessageEvent) => {
      const message = JSON.parse(e.data) as {
        from: string
        to?: string
        project?: string
        type: string
        data: object
      }

      if (message.type === 'project-started') {
        ws.send({
          type: 'display',
          project: project.id,
          data: {},
        })
        ws.send({
          type: 'source',
          project: project.id,
          data: {},
        })
      }

      if (message.type === 'display-offer') {
        const data = message.data as {
          sdp: string
        }

        await displayPeerConnection.setRemoteDescription({
          type: 'offer',
          sdp: data.sdp,
        })

        const answer = await displayPeerConnection.createAnswer()
        await displayPeerConnection.setLocalDescription(answer)

        ws.send({
          type: 'display-answer',
          project: message.project,
          data: {
            sdp: answer.sdp,
          },
        })
      }

      if (message.type === 'source-offer') {
        const data = message.data as {
          sdp: string
        }

        const answer = await makeSourcePeerConnection({
          type: 'offer',
          sdp: data.sdp,
        })

        ws.send({
          type: 'source-answer',
          project: message.project,
          data: {
            sdp: answer.sdp,
          },
        })
      }
    })

    return () => ws.close()
  }, [project])

  if (!isAuthenticated) {
    login()
    return <></>
  }

  return (
    <>
      <div className="wrap">
        <div className="header-bar">
          <div className="container">
            <div className="logo">
              <Link to="/">veveo.ru</Link>
            </div>
            {project !== null ? <div className="name">{project.name}</div> : null}
            <nav>
              <a
                data-testid="logout-link"
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  logout()
                }}
              >
                Выход
              </a>
            </nav>
          </div>
        </div>
        <div className="container">
          {project !== null ? (
            <div data-testid="project">
              <div className={styles.screen}>
                <div className="responsive responsive-16x9">
                  <video ref={screen} autoPlay controls />
                </div>
              </div>
              <div className={styles.sources}>
                <div className={styles.source}>
                  <div className="responsive responsive-16x9">
                    <video ref={source} autoPlay />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div>&copy; veveo.ru</div>
        </div>
      </div>
    </>
  )
}

export default Project
